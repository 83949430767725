import ParallaxItem from './ParallaxItem'
import { query } from '../utils/dom'
import support from '../utils/BrowserSupport'

export default class SectionMain extends ParallaxItem {

    constructor(element, options, smoothScroll) {
        super(element, options);

        this.smoothScroll = smoothScroll

        this.link = query('.MoreLink', this.element)
        this.link.addEventListener('click', ::this.handleClick)
        this.button = query('.ScrollButton', this.element)
        this.button.addEventListener('click', ::this.handleClick)

        this.videoContainer = query('.SectionVideo')
        this.video = query('video', this.videoContainer)

        window.addEventListener('resize', ::this.resizeVideo)

        this.resize()
        this.resizeVideo()
        this.render()

        this.isPlaying = false
        this.isVideoUnavailable = false
    }

    handleClick(event) {
        event.preventDefault()
        this.smoothScroll.scrollToElement('.SectionContent')
    }

    render(scroll) {
        if (scroll > this.height) {
            if (this.isPlaying) {
                this.pause()
            }
            return;
        }

        const ratio = scroll/this.height
        const renderRatio = Math.min(scroll/(this.height/2), 1)
        const scale = 1 - ratio * .1

        this.element.style[support.transform] = `translateY(${scroll}px) scale(${scale})`
        this.element.style.opacity = (1 - renderRatio) * .8 + .2

        if (!this.isPlaying) {
            this.play()
        }

    }

    play() {
        if (this.isVideoUnavailable) {
            return
        }
        this.video.play()
        if (this.video.paused) {
            this.videoContainer.classList.add('is-video-unavailable')
            this.isVideoUnavailable = true
        }
        this.isPlaying = true
        this.videoContainer.classList.add('is-playing')
    }

    pause() {
        this.video.pause()
        this.isPlaying = false
        this.videoContainer.classList.remove('is-playing')
    }

    resize() {
        this.height = this.element.clientHeight
    }

    resizeVideo() {
        const width = window.innerWidth || window.clientWidth
        const height = window.innerHeight || window.clientHeight

        let videoWidth = 0
        let videoHeight = 0

        if (width/height > 16/9) {
            videoWidth = width
            videoHeight = width/16*9
        } else {
            videoHeight = height
            videoWidth = height/9*16
        }

        this.videoContainer.style.width = `${videoWidth}px`
        this.videoContainer.style.height = `${videoHeight}px`
    }

}