import support from '../utils/BrowserSupport';
import { query } from '../utils/dom';
import getScrollTop from '../utils/getScrollTop';

const defaults = {
    root: window,
    spread: 30
}

export default class ParallaxItem {

    constructor(element, options) {
        this.element = query(element);

        this.options = {
            ...defaults,
            ...options
        };

        this.active = false;
        this.lastOffset = 0;
        this.baseOffset = null;
        this.ratio = 0.075;
        this.defaultBox = null;

        this.resize();

    }

    onEnter() {
        if (this.options.onEnter) {
            this.options.onEnter();
        }
    }

    onLeave() {
        if (this.options.onLeave) {
            this.options.onLeave();
        }
    }

    calculate(scrollTop) {

        if (this.defaultBox) {
            let ratio = 0;

            if (scrollTop < this.min) {
                ratio = -1;
            } else if (scrollTop > this.max) {
                ratio = 1;
            } else {
                ratio = (scrollTop - this.min)/(this.max - this.min) * 2 - 1;
            }

            if (ratio === 1 || ratio === -1) {
                if (this.active) {
                    this.active = false;
                    this.onLeave();
                }
            } else {
                if (!this.active) {
                    this.active = true;
                    this.onEnter();
                }
            }

            this.render(scrollTop, ratio);
        } else {
            this.render(scrollTop);
        }
    }

    render(scrollTop, ratio = 0) {
        if (scrollTop < this.min || scrollTop > this.max) {
            return;
        }

        const offset = this.options.spread * ratio;

        if (this.baseOffset === null) {
            this.baseOffset = offset;
        }

        if (Math.abs(offset - this.lastOffset) > 0.05) {
            this.element.style[support.transform] = `translateY(${offset}px) translateZ(0)`;
            this.lastOffset = offset;
        }
    }

    reset() {
        this.element.style[support.transform] = `translateY(${-this.baseOffset}px) translateZ(0)`;
    }

    resize() {
        this.baseOffset = null;

        const box = this.element.getBoundingClientRect();
        const scrollTop = getScrollTop();
        const top = box.top + scrollTop;
        const bottom = box.bottom + scrollTop;

        const rootHeight = this.options.root.innerHeight || this.options.root.clientHeight;
        const rootHeightHalf = rootHeight/2;

        const min = top - rootHeight - this.options.spread;
        const max = bottom + this.options.spread;
        const center = Math.round((max - min)/2 + min);

        this.defaultBox = box;
        this.rootHeight = rootHeight;
        this.rootHeightHalf = rootHeightHalf;
        this.min = min;
        this.max = max;

        if (scrollTop < this.min) {
            this.render(this.min);
        }

        if (scrollTop > this.max) {
            this.render(this.max);
        }

        this.calculate(getScrollTop());
    }

    transform(element, value) {
        this.element.style[support.transform] = `translateY(${value}px) translateZ(0)`;
    }

}