// import 'core-js/fn/promise';

import SmoothScroll from './components/SmoothScroll'
import { query, queryAll } from './utils/dom'
import ParallaxItem from './components/ParallaxItem'

import SectionMain from './components/SectionMain'
import Footer from './components/Footer'

const sectionMain = document.querySelector('.SectionMain')

if (sectionMain) {
    const items = queryAll('[data-parallax]').map(
        (item) =>
            new ParallaxItem(item, {
                spread: item.getAttribute('parallax')
                    ? parseFloat(item.getAttribute('parallax'))
                    : 50,
            }),
    )

    const smoothScroll = new SmoothScroll(query('.scroll-Container'))

    items.push(new SectionMain('.SectionMain', {}, smoothScroll))
    items.push(new Footer('.Footer', {}, smoothScroll))

    smoothScroll.add(items)

    const logo = query('.Logo')
    logo.addEventListener('click', (event) => {
        event.preventDefault()
        smoothScroll.scrollToTop()
    })

    window.addEventListener('load', () => {
        smoothScroll.resize()
    })
}
