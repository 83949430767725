import ParallaxItem from './ParallaxItem'
import { query } from '../utils/dom'
import support from '../utils/BrowserSupport'

export default class Footer extends ParallaxItem {

    constructor(element, options, smoothScroll) {
        super(element, options);

        this.smoothScroll = smoothScroll

        this.link = query('.Footer-upLink', this.element)
        this.link.addEventListener('click', ::this.handleClick)
    }

    handleClick(event) {
        event.preventDefault()
        this.smoothScroll.scrollToTop()
    }

    render(scroll) {
        if (!this.smoothScroll) {
            return
        }

        if (scroll + this.rootHeight < this.smoothScroll.height - this.defaultBox.height) {
            return
        }

        const offset = scroll + this.rootHeight - this.smoothScroll.height

        this.element.style[support.transform] = `translateY(${offset}px)`
    }

}